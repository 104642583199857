import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import Container from '../Container/Container'
import { motion } from 'framer-motion'
import config from '../../config'

const StyledHeader = styled.header`
  min-height: 90vh;
  display: grid;
  place-items: center;
  padding: 50px 0;

  @media only screen and (max-width: 768px) {
    padding: 10px 0;
  }
`

const StyledContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const AnimateWrapper = styled.div`
  overflow: hidden;
`
const Title = styled(motion.h1)`
  font-size: 6rem;
  margin-bottom: 1rem;
  color: var(--primary);

  @media only screen and (max-width: 768px) {
    font-size: 3.2rem;
  }
`

const Caption = styled(motion.p)`
  max-width: 400px;
  margin-bottom: 40px;
  margin-top: 20px;
  line-height: 1.7;
  font-size: 1.6rem;
  color: var(--gray);
`

const PhoneNumber = styled.a`
  display: flex;
  background: linear-gradient(90deg, var(--yellow), var(--yellow-second));
  color: #fff;
  width: max-content;
  padding: 10px 40px;
  font-size: 3rem;
  margin: 2rem auto 0 auto;
  border-radius: 10px;
  cursor: pointer;
`

const HomepageHeader = () => {
  return (
    <StyledHeader>
      <StyledContainer>
        <Column>
          <AnimateWrapper>
            <Title
              initial={{ y: 100 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.8, delay: 0 }}
            >
              Pomagamy
            </Title>
          </AnimateWrapper>
          <AnimateWrapper>
            <Title
              initial={{ y: 100 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.8, delay: 0.6 }}
            >
              zaoszczędzić
            </Title>
          </AnimateWrapper>

          <Caption
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 1.3 }}
          >
            SOL-X wykorzystując znajmość prawa energetycznego oraz unikatowe
            know how z rynku zapewniamy swoim Klientom oszczędności finansowe,
            czasu a także zanieczyszczenia.
          </Caption>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 1.8 }}
          >
            <StaticImage
              src="../../images/header-values.jpg"
              placeholder="tracedSVG"
              alt="Sol-x"
              width={400}
            />
          </motion.div>
        </Column>
        <Column>
          <StaticImage
            src="../../images/cooperation.jpg"
            placeholder="blurred"
            alt="solx"
          />
          <PhoneNumber href={`tel:${config.phoneNumber.replaceAll(' ', '')}`}>
            {config.phoneNumber}
          </PhoneNumber>
        </Column>
      </StyledContainer>
    </StyledHeader>
  )
}

export default HomepageHeader

import React from 'react'
import styled from 'styled-components'

const StyledTitle = styled.h2`
  color: var(--primary);
  padding: 10px 0;
  margin-top: ${({ marginTop }) => marginTop || 0};
  font-size: 4rem;
  margin-bottom: 40px;
  display: flex;

  @media only screen and (max-width: 768px) {
    font-size: 1.9rem;
    margin-bottom: 20px;

    display: inline;
  }

  .accent {
    color: var(--yellow);
  }
`

const Box = styled.aside`
  display: block;
  background: var(--yellow);
  width: 20px;
  height: 4rem;
  margin-right: 10px;

  @media only screen and (max-width: 768px) {
    height: 2.3rem;

    display: none;
  }
`

const Subtitle = ({ children, ...props }) => {
  return (
    <StyledTitle {...props}>
      <Box />
      {children}
    </StyledTitle>
  )
}

export default Subtitle

import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  padding: 0 20px;
  margin: 0 auto;
`

const Container = ({ children, ...props }) => (
  <StyledContainer {...props}>{children}</StyledContainer>
)

export default Container

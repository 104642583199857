import React from 'react'
import styled from 'styled-components'

const StyledTitle = styled.h2`
  color: var(--primary);
  padding: 10px 0;
  margin-top: ${({ marginTop }) => marginTop || 0};
  font-size: 4.5rem;
  margin-bottom: 20px;

  @media only screen and (max-width: 768px) {
    font-size: 2.3rem;
  }

  &:hover {
    color: var(--primary);
    border-color: var(--primary);
  }
`

const Title = ({ children, ...props }) => {
  return <StyledTitle {...props}>{children}</StyledTitle>
}

export default Title

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Logo = ({ name, ...props }) => {
  return (
    <StaticImage
      placeholder="tracedSVG"
      src={`../../images/brand/logo.png`}
      alt="Sol-x"
      width={140}
    />
  )
}

export default Logo

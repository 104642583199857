import React from 'react'
import styled from 'styled-components'
import Container from '../Container/Container'
import { StaticImage } from 'gatsby-plugin-image'
import Title from '../Title/Title'

const Wrapper = styled.section`
  padding: 20px 0 30px 0;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1) inset;
`

const StyledContainer = styled(Container)``

const OfferCardsWrapper = styled.main`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Caption = styled.p`
  font-size: 2rem;
  font-weight: 300;
  color: var(--gray);
  margin-top: 30px;
`

const Offer = () => {
  return (
    <Wrapper>
      <StyledContainer>
        <Title>Oferta</Title>
        <OfferCardsWrapper>
          <StaticImage
            placeholder="tracedSVG"
            src="../../images/offer1.jpg"
            alt="Optymalizacja"
          />
          <StaticImage
            placeholder="tracedSVG"
            src="../../images/offer2.jpg"
            alt="Inwestycja"
          />
          <StaticImage
            placeholder="tracedSVG"
            src="../../images/offer3.jpg"
            alt="Doradztwo"
          />
          <StaticImage
            placeholder="tracedSVG"
            src="../../images/offer4.jpg"
            alt="Monitoring"
          />
        </OfferCardsWrapper>
        <Caption>
          Przy optymalizacji działamy na zasadach <strong>success fee</strong>,
          w naszym interesie jest, żeby Państwo osiągnęli jak największe
          oszczędności.
        </Caption>
      </StyledContainer>
    </Wrapper>
  )
}

export default Offer

import { Link } from 'gatsby'
import React, { useState } from 'react'
import styled from 'styled-components'
import Container from '../Container/Container'
import MenuToggle from '../Layout/MenuToggle'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { StaticImage } from 'gatsby-plugin-image'

const menuElements = [
  {
    title: 'Optymalizacja',
    link: '#optymalizacja',
  },
  {
    title: 'Inwestycja',
    link: '#inwestycja',
  },
  {
    title: 'Doradztwo',
    link: '#doradztwo',
  },
  {
    title: 'Monitoring',
    link: '#monitoring',
  },
  {
    title: 'Kontakt',
    link: '#kontakt',
  },
]

const NavigationContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
`

const NavigationLink = styled.span`
  padding: 15px 35px;
  display: block;
  white-space: nowrap;
  text-align: center;
  font-weight: 600;
  cursor: pointer !important;

  &:hover {
    color: var(--yellow);
  }

  &.secondary {
    color: var(--primary);
    background-color: #eceeff;

    &:hover {
      background-color: #d3d6f5;
    }
  }

  &:last-child {
    color: var(--yellow);
  }
`

const MenuWrapper = styled.main`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    color: var(--primary);
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 300px;
    max-width: 70vw;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
    padding-top: 100px;
    z-index: 5;
    transition: all 0.3s ease;
    background-color: white;

    transform: ${({ menuOpen }) =>
      menuOpen ? 'translateX(0)' : 'translateX(100%);'};
    box-shadow: ${({ menuOpen }) =>
      menuOpen ? '0 0 5px 5px rgba(0, 0, 0, 0.1);' : 'none;'};
  }
`

const NavigationItems = ({ ...props }) => {
  return (
    <MenuWrapper {...props}>
      {menuElements.map((el) => (
        <NavigationLink onClick={() => scrollTo(el.link)} key={el.title}>
          {el.title}
        </NavigationLink>
      ))}
    </MenuWrapper>
  )
}

const StyledMenuToggle = styled(MenuToggle)`
  --size: 40px;
  width: var(--size);
  height: var(--size);
  cursor: pointer;
  position: absolute;
  right: 20px;
  z-index: 10;

  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }

  .center,
  .top,
  .bottom {
    transition: transform 0.2s ease, opacity 0.2s ease;

    width: transform, opacity;
  }

  .top {
    transform-origin: 68% 52%;
  }

  .bottom {
    transform-origin: 69% 50%;
  }

  &.active {
    color: var(--primary);
    position: fixed;
    .center {
      opacity: 0;
    }

    .top {
      transform: rotate(-45deg);
    }

    .bottom {
      transform: rotate(45deg);
    }
  }
`

const AllNavWrapper = styled.nav`
  background: var(--primary);
  color: white;
  border-bottom: 2px solid var(--yellow);
`

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <AllNavWrapper>
      <NavigationContainer>
        <Link to="/">
          <StaticImage
            placeholder="tracedSVG"
            src={`../../images/brand/logo-white.png`}
            alt="Sol-x"
            width={140}
          />
        </Link>
        <NavigationItems menuOpen={menuOpen} />
        <StyledMenuToggle
          className={menuOpen && 'active'}
          onClick={() => setMenuOpen(!menuOpen)}
        />
      </NavigationContainer>
    </AllNavWrapper>
  )
}

export default Navigation

import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import Container from '../Container/Container'
import Subtitle from '../Title/Subtitle'

const MonitoringSection = styled.section`
  padding: 50px 0;

  @media only screen and (max-width: 768px) {
    padding: 20px;
  }
`

const TextColumns = styled.article`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 120px;
  justify-items: center;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 40px;
    margin-top: 40px;
  }
`

const TextColumn = styled.div`
  color: var(--gray);
  font-size: 1.6rem;
  line-height: 1.7;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    color: var(--primary);
    font-size: 3rem;
    font-weight: 300;
    margin-bottom: 20px;
  }

  img {
    max-height: 120px;
    margin-bottom: 15px;
  }
`

const Monitoring = ({ children, ...props }) => {
  return (
    <MonitoringSection {...props}>
      <Container>
        <Subtitle>
          <span className="accent">Monitoring - </span>&nbsp;czyli zadbamy o
          Państwa w przyszłości
        </Subtitle>
        <TextColumns>
          <TextColumn>
            <StaticImage
              placeholder="tracedSVG"
              src="../../images/mon1.jpg"
              alt="Opieka handlowa"
            />
            <h3>Opieka handlowa</h3>
            <ul>
              <li>Poprawność rozliczeń za energię oraz usługi dystrybucyjne</li>
              <li>
                Dostosowanie warunków umów do zmieniających się regulacji
                sektora energetycznego
              </li>
              <li>
                Reprezentacaj klienta w zakresie wszelkich zagadnień
                wymagających kontaktu z&nbsp;Uczestikami Rynku
              </li>
            </ul>
          </TextColumn>
          <TextColumn>
            <StaticImage
              placeholder="tracedSVG"
              src="../../images/mon2.jpg"
              alt="Monitoring techniczny"
            />
            <h3>Monitoring techniczny</h3>
            <ul>
              <li>
                Prowadzenie procesów podłączania nowych punktów poboru energii
              </li>
              <li>Ciągły dobór wymaganych parametrów umownych</li>
              <li>
                Zapewnienie odczytów rzeczywistych dla klentów rozliczanych w
                oparciu o prognozy zużycia (zależy od dostępności usługi u
                Operatora Systemu Dystrybucyjnego)
              </li>
            </ul>
          </TextColumn>
        </TextColumns>
      </Container>
    </MonitoringSection>
  )
}

export default Monitoring

import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import Container from '../Container/Container'
import Subtitle from '../Title/Subtitle'

const OptimizationSection = styled.section`
  padding: 50px 0;

  @media only screen and (max-width: 768px) {
    padding: 20px 0;
  }
`

const TextColumns = styled.article`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 70px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 30px;
    margin-top: 30px;
  }
`

const TextColumn = styled.p`
  color: var(--gray);
  font-size: 1.6rem;
  line-height: 1.7;
  font-weight: 300;
  position: relative;

  @media only screen and (max-width: 768px) {
    padding-left: 20px;
  }

  &:before {
    position: absolute;
    content: '${({ number }) => number}';
    font-weight: bold;
    color: var(--primary);
    left: -30px;
    font-size: 5rem;
    top: -30px;
    opacity: 0.3;

    @media only screen and (max-width: 768px) {
      font-size: 3rem;
      left: -10px;
    }
  }
`

const OptimizationTitle = styled.h3`
  text-align: center;
  font-weight: 300;
  margin: 60px auto 20px auto;
  font-size: 4rem;
  color: var(--primary);
`

const Optimization = ({ children, ...props }) => {
  return (
    <OptimizationSection {...props}>
      <Container>
        <Subtitle>
          {'Na czym polega '}
          <span className="accent">&nbsp;optymalizacja&nbsp;</span>
          {' kosztów energii?'}
        </Subtitle>
        <TextColumns>
          <TextColumn number="1">
            To przede wszystkim gruntowna analiza rachunków za energię
            elektryczną. Weryfikacja posiadanych certyfikatów i zezwoleń.
            Przegląd dokumentów i umów związanych z dostawą energii.
          </TextColumn>
          <TextColumn number="2">
            Kiedy zbierzemy już niezbędne dane przygotowujemy raport z
            propozycjami dostarczającymi oszczędności niewymagających żadnych
            nakładów inwestycyjnych dla naszych klientów.
          </TextColumn>
          <TextColumn number="3">
            Po akceptacji przechodzimy do fazy realizacji, gdzie w krótkim
            czasie zaczynają pojawiać się pierwsze zyski.
          </TextColumn>
        </TextColumns>
        <OptimizationTitle>OBNIŻENIE KOSZTÓW:</OptimizationTitle>
        <StaticImage
          placeholder="tracedSVG"
          src="../../images/costs.jpg"
          alt="Obniżenie kosztów"
        />
      </Container>
    </OptimizationSection>
  )
}

export default Optimization

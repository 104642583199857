import { Link } from 'gatsby'
import React, { useState } from 'react'
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'
import config from '../../config'
import Container from '../Container/Container'
import StyledLogo from '../Logo/Logo'

const Wrapper = styled.section`
  background-color: rgb(230, 230, 230);
  padding: 100px 0;
  text-align: center;

  @media only screen and (max-width: 768px) {
    padding: 30px 0;
  }
`
const StyledContainer = styled(Container)``

const Button = styled.button`
  background-color: var(--primary);
  color: #fff;
  padding: 10px 50px;
  width: max-content;
  cursor: pointer;
  border: 1px solid transparent;
  margin-top: 3rem;

  &:hover {
    color: var(--primary);
    background-color: transparent;
    border-color: var(--primary);
  }
`

const FormGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 10px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`

const ContactFooter = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;

  a:last-of-type {
    margin-bottom: 20px;
  }
`

const FormWrapper = styled.section``

const StyledForm = styled(Form)`
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border: 10px solid var(--yellow);
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  font-size: 1.5rem;

  label {
    margin-bottom: 5px;
    margin-top: 15px;
    text-align: left;
  }

  input,
  textarea {
    padding: 5px;
    border: 1px solid rgba(140, 140, 140);
    border-radius: 3px;
    font-family: inherit;
    width: 100%;
    resize: none;
    outline-color: var(--primary);
  }

  .field {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .checkbox {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 20px 1fr;

    label {
      margin: 0;
      transform: translateY(-2px);
    }
  }
`

const ErrorWrapper = styled.div`
  padding-top: 3px;
  margin-top: 10px;
  font-weight: 300;
  background-color: rgba(255, 0, 0, 0.1);
  border: 2px solid rgba(255, 0, 0, 0.3);
  font-size: 1.4rem;
  p {
    margin-bottom: 3px;
  }
`

const Contact = ({ ...props }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    companyName: '',
    message: '',
  })

  const [messages, setMessages] = useState([])
  const [buttonText, setButtonText] = useState('Wyślij')
  const [formSubimitted, setFormSubmitted] = useState(false)

  const handleFormSubmit = (e) => {
    e.preventDefault()

    if (formSubimitted) {
      setButtonText('Wiadomość już została wysłana.')
      return
    }

    const { firstName, lastName, phoneNumber, companyName, message } = formData

    let terms = document.querySelector(
      '.checkboxInput input[type="checkbox"]'
    ).checked

    const tempMessages = []

    firstName || tempMessages.push('Imię jest wymagane.')
    companyName || tempMessages.push('Nazwa firmy jest wymagana.')
    phoneNumber || tempMessages.push('Numer telefonu jest wymagany.')
    message || tempMessages.push('Treść wiadomośći jest wymagana.')
    terms || tempMessages.push('Zaznaczanie zgody jest wymagane.')

    setMessages(tempMessages)

    if (tempMessages.length) return
    else {
      let formDataObj = new FormData()
      formDataObj.append('firstName', firstName)
      formDataObj.append('lastName', lastName)
      formDataObj.append('companyName', companyName)
      formDataObj.append('phoneNumber', phoneNumber)
      formDataObj.append('message', message)

      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formDataObj).toString(),
      })
        .then(() => {
          setButtonText('Wiadomość wysłana poprawnie')
          setFormSubmitted(true)
        })
        .catch((error) =>
          setButtonText('Wystąpił błąd podczas wysyłania wiadomości.')
        )
    }
  }
  return (
    <Wrapper {...props}>
      <StyledContainer>
        <FormWrapper>
          <StyledForm name="contact" method="POST" data-netlify="true">
            <Form>
              <FormGroup>
                <Form.Input
                  fluid
                  label="Imię"
                  placeholder="Imię"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      firstName: e.target.value.trim(),
                    })
                  }
                />
                <Form.Input
                  fluid
                  label="Nazwisko"
                  placeholder="Nazwisko"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      lastName: e.target.value.trim(),
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Form.Input
                  fluid
                  label="Nazwa firmy"
                  placeholder="Nazwa firmy"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      companyName: e.target.value.trim(),
                    })
                  }
                />
                <Form.Input
                  fluid
                  label="Numer telefonu"
                  placeholder="Numer telefonu"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      phoneNumber: e.target.value.trim(),
                    })
                  }
                />
              </FormGroup>
              <Form.TextArea
                label="Wiadomość"
                placeholder="Treść wiadomości"
                rows={10}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    message: e.target.value.trim(),
                  })
                }
              />
              <Form.Checkbox
                label="Akceptuję zasady zawarte w polityce prywatności"
                className="checkboxInput"
              />
              <Button onClick={handleFormSubmit}>{buttonText}</Button>

              {messages.length ? (
                <ErrorWrapper>
                  {messages.map((m) => (
                    <p>{m}</p>
                  ))}
                </ErrorWrapper>
              ) : null}
            </Form>
          </StyledForm>
        </FormWrapper>
        <ContactFooter>
          <StyledLogo />
          <br />
          <a href={`mailto:${config.emailAdress}`}>{config.emailAdress}</a>
          <br />
          <a href={`tel:${config.phoneNumber.replaceAll(' ', '')}`}>
            {config.phoneNumber}
          </a>
          <br />
          <span>{config.companyName}</span>
          <span>{config.street}</span>
          <span>
            {config.postcode} {config.city}
          </span>
          <span>NIP {config.nip}</span>
          <br />
          <Link to="/polityka-prywatnosci/">Polityka prywatności</Link>
        </ContactFooter>
      </StyledContainer>
    </Wrapper>
  )
}

export default Contact

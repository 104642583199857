import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import Container from '../Container/Container'
import Subtitle from '../Title/Subtitle'

const InvestmentSection = styled.section`
  padding: 50px 0;

  @media only screen and (max-width: 768px) {
    padding: 20px 0;
  }
`

const TextColumns = styled.article`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 70px;
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
`

const TextColumn = styled.div`
  color: var(--gray);
  font-size: 1.6rem;
  line-height: 1.7;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-height: 120px;
    margin-bottom: 15px;
  }
`

const Investment = ({ children, ...props }) => {
  return (
    <InvestmentSection {...props}>
      <Container>
        <Subtitle>
          Czym jest model&nbsp;
          <span className="accent">oszczędzania przez inwestowanie?</span>
        </Subtitle>
        <TextColumns>
          <TextColumn>
            <StaticImage
              placeholder="tracedSVG"
              src="../../images/inv1.jpg"
              alt="Oszczędności"
            />
            <p>
              Pierwsze oszczędności, które generujemy dla naszych Klientów nie
              wymagają nakładów finansowych. Jednak każdy może zyskać jeszcze
              więcej inwestując w nowe technologie pozwalające zwiększyć zysk w
              obszarze energetyki.
            </p>
          </TextColumn>
          <TextColumn>
            <StaticImage
              placeholder="tracedSVG"
              src="../../images/inv2.jpg"
              alt="Bezpieczny zysk"
            />
            <p>
              Zakup instalacji fotowoltaicznej, kompensatorów mocy biernej czy
              magazynów energii choć nie wydaje się tani, jest gwarantem zysku
              bezpieczniejszego i większego niż lokata bankowa.
            </p>
          </TextColumn>
          <TextColumn>
            <StaticImage
              placeholder="tracedSVG"
              src="../../images/inv3.jpg"
              alt="Zwrot inwestycji"
            />
            <p>
              Przy obecnym skoku cen samej energii, jak i jej dystrybucji zwrot
              kosztów poniesionych nakładów następuje w czasie nawet 3 lat!
            </p>
          </TextColumn>
        </TextColumns>
      </Container>
    </InvestmentSection>
  )
}

export default Investment

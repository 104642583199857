import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import Container from '../Container/Container'
import Subtitle from '../Title/Subtitle'

const InvestmentSection = styled.section`
  padding: 50px 0;

  @media only screen and (max-width: 768px) {
    padding: 20px 0;
  }
`

const TextColumns = styled.article`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 70px;
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
  width: 100%;
`

const ImageText = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`

const TextColumn = styled.div`
  color: var(--gray);
  font-size: 1.6rem;
  line-height: 1.7;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: 768px) {
    padding-left: 13px;
  }

  img {
    max-height: 40px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  h3 {
    font-size: 1.6rem;
    margin-left: 10px;
    text-align: center;
    color: var(--primary);
  }

  ul {
    font-size: 1.45rem;
  }
`

const Consulting = ({ children, ...props }) => {
  return (
    <InvestmentSection {...props}>
      <Container>
        <Subtitle>
          <span className="accent">Doradztwo&nbsp;</span>- jak działamy
        </Subtitle>
        <TextColumns>
          <TextColumn>
            <ImageText>
              <StaticImage
                placeholder="tracedSVG"
                src="../../images/doradztwo1.png"
                alt="Zainteresowanie propozycją"
              />
              <h3>
                Zainteresowanie
                <br />
                propozycją
              </h3>
            </ImageText>

            <ul>
              <li>Przedstawienie możliwych oszczędności (prezentacja)</li>
              <li>
                Identyfikacja właściwych osób w firmie (faktury, dane, decyzje)
              </li>
              <li>Wstępna identyfikacja potrzeb (obszary oszczędności)</li>
            </ul>
          </TextColumn>
          <TextColumn>
            <ImageText>
              <StaticImage
                placeholder="tracedSVG"
                src="../../images/doradztwo2.png"
                alt="Pozyskanie faktur"
              />
              <h3>
                Pozyskanie
                <br />
                faktur
              </h3>
            </ImageText>

            <ul>
              <li>Faktury sprzedażowe i faktury dystrybucyjne</li>
              <li>Faktury kompleksowe za okres pełnego roku</li>
            </ul>
          </TextColumn>
          <TextColumn>
            <ImageText>
              <StaticImage
                placeholder="tracedSVG"
                src="../../images/doradztwo3.png"
                alt="Złożenie oferty"
              />
              <h3>
                Złożenie
                <br />
                oferty
              </h3>
            </ImageText>

            <ul>
              <li>Obszary oszczędności i sposoby ich pozyskiwania</li>
              <li>Zakres niezbędnych informacji do analizy (poza fakturami)</li>
              <li>
                Warunki handlowe w tym umowa, terminy realizacji, ceny i
                rozliczenia
              </li>
            </ul>
          </TextColumn>
          <TextColumn>
            <ImageText>
              <StaticImage
                placeholder="tracedSVG"
                src="../../images/doradztwo4.png"
                alt="Podpisanie umowy"
              />
              <h3>
                Podpisanie
                <br />
                umowy
              </h3>
            </ImageText>

            <ul>
              <li>
                Negocjacje handlowe i skompletowanie dokumentów w tym umowy,
                pełnomocnictwa, wyciągu z KRS
              </li>
              <li>
                Zebranie podpisów osób uprawnionych do podpisania oraz
                przekazanie scan-ów i oryginałów do SOL-X (biuro@sol-x.pl)
              </li>
            </ul>
          </TextColumn>
          <TextColumn>
            <ImageText>
              <StaticImage
                placeholder="tracedSVG"
                src="../../images/doradztwo5.png"
                alt="Zebranie danych i szczegółowa analiza"
              />
              <h3>
                Zebranie danych
                <br />i szczegółowa analiza
              </h3>
            </ImageText>

            <ul>
              <li>Wypełnienie ankiety</li>
              <li>Skompletowanie faktur</li>
              <li>Pozyskanie danych pomiarowych</li>
              <li>Szczegółowa analiza</li>
            </ul>
          </TextColumn>
          <TextColumn>
            <ImageText>
              <StaticImage
                placeholder="tracedSVG"
                src="../../images/doradztwo6.png"
                alt="Przekazanie i prezentacja raportu"
              />
              <h3>
                Prezentacja i&nbsp;przekazanie
                <br />
                raportu
              </h3>
            </ImageText>

            <ul>
              <li>Kalkulacja oszczędności</li>
              <li>Rekomendowane działania</li>
              <li>Decyzje klienta</li>
            </ul>
          </TextColumn>
          <TextColumn>
            <ImageText>
              <StaticImage
                placeholder="tracedSVG"
                src="../../images/doradztwo7.png"
                alt="Dostarczenie oszczędności"
              />
              <h3>
                Dostarczenie
                <br />
                oszczędności
              </h3>
            </ImageText>

            <ul>
              <li>Negocjacje stawek i zmiana sprzedawcy</li>
              <li>Zmiana grupy taryfowej</li>
              <li>Zmiana mocy umownej</li>
            </ul>
          </TextColumn>
          <TextColumn>
            <ImageText>
              <StaticImage
                placeholder="tracedSVG"
                src="../../images/doradztwo8.png"
                alt="Rozliczenie oszczędności"
              />
              <h3>
                Rozliczenie
                <br />
                oszczędności
              </h3>
            </ImageText>

            <ul>
              <li>Wystawienie faktur</li>
              <li>Przedłużenie umowy na opiekę energetyczna</li>
              <li>Pozyskanie referencji</li>
            </ul>
          </TextColumn>
        </TextColumns>
      </Container>
    </InvestmentSection>
  )
}

export default Consulting

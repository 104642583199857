import React from 'react'
import styled from 'styled-components'
// import Footer from '../Footer/Footer'
import Navigation from '../Navigation/Navigation'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
:root {
--primary: #33447a;
--yellow: #faac16;
--yellow-second: #fac402;
--gray: rgb(40,40,40);
}

    html {
        font-size: 62.5%;
        box-sizing: border-box;
        scroll-behavior: smooth;
    }

    * {
        box-sizing: border-box;
    }

    body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    font-size: 1.4rem;
    box-sizing: border-box;
    }

  a {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
  }

  *, h1, h2, h3, h4, p, ul, li, ol {
    padding: 0;
    margin: 0;
  }
`

const StyledLayout = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const Layout = ({ children }) => {
  return (
    <StyledLayout>
      <GlobalStyle />
      <Navigation />
      {children}
      {/* <Footer /> */}
    </StyledLayout>
  )
}

export default Layout

import * as React from 'react'
import HomepageHeader from '../components/HomepageHeader/HomepageHeader'
import Offer from '../components/Offer/Offer'
import Contact from '../components/Contact/Contact'
import Layout from '../components/Layout/Layout'
import { Helmet } from 'react-helmet'
import Optimization from '../components/Optimization/Optimization'
import Investment from '../components/Investment/Investment'
import Monitoring from '../components/Monitoring/Monitoring'
import Consulting from '../components/Consulting/Consulting'

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>SOL-X</title>
        <meta name="description" content="Description" />
      </Helmet>

      <HomepageHeader />
      <Offer />
      <Optimization id="optymalizacja" />
      <Investment id="inwestycja" />
      <Consulting id="doradztwo" />
      <Monitoring id="monitoring" />
      <Contact id="kontakt" />
    </Layout>
  )
}

export default IndexPage
